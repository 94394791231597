.navigator-bar {
  position: fixed;
  padding-top: 50px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  height: 100px;
}

.navigator-wrapper {
  position: fixed;
  padding-top: 50px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  line-height: 40px;
  display: flex;
  max-width: 1220px;
  user-select: none;
  margin: auto;

  //   justify-content: space-between;
  //   align-items: center;
}

.navigator-bar-active p {
  color: rgba(255, 255, 255, 1) !important;
}

.navigator-bar-item {
  flex: 1;
  position: relative;
  cursor: pointer;
  &:hover {
    .navigation-sub-menu {
      display: flex;
    }
  }
  .navigation-item-title {
    font-size: 22px;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-weight: bold;
    &:hover {
      color: #fff;
    }
    &-active {
      color: #fff;
    }
    // &:hover {
    //   & + .navigation-sub-menu {
    //     display: flex;
    //   }
    // }
  }
  img {
    display: block;
    width: 100px;
    // height: 36px;
    margin: 6px auto;
  }
  .navigation-sub-menu {
    // width: 276px;
    position: absolute;
    padding-top: 10px;
    left: -24px;
    // margin: 28px 0 0 16px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.6);
    // text-align: center;
    // display: flex;
    display: none;
    // &:hover {
    //   display: flex;
    // }
    &-item {
      // width: 100px;
      margin-left: 16px;
      &:hover {
        // opacity: 1;
        color: #fff;
      }
      &:first-child {
        margin-left: 0;
      }
      &-active {
        color: #fff;
      }
    }
  }
}

.active-navigation-bar {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fff;
  left: 50%;
  margin-left: -2.5px;
  top: 40px;
}
